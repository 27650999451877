import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import ContactForm from '../components/sections/contact-form'
import HeroFullScreenPhoto from '../components/sections/hero-full-screen-photo'
import Partnerships from '../components/common/partnerships'
import OurPartners from '../components/sections/our-partners'
import Testimonials from '../components/sections/testimonials-static'
import WhyThinkPlus from '../components/sections/why-think-plus'
import SectionHeader from '../components/common/section-header'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import '../styles/pages/page-landingpage.scss'
import { faqWordpress } from '../utils/schema/faq'
import { wordpress as servicesSchema } from '../utils/schema/services'

const pageWordpressWebsite = ({ location }) => {
  const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-wordpress-website"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-wordpress.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
	caseStudy_HDC: file(relativePath: {eq: "wordpresswebsite/CaseStudy_HDC.png"}) {
    childImageSharp {
      gatsbyImageData(width: 664, layout: CONSTRAINED)
    }
  }
  caseStudy_alexandrion: file(relativePath: {eq: "wordpresswebsite/tp-casestudy-alexandrion.png"}) {
    childImageSharp {
      gatsbyImageData(width: 664, layout: CONSTRAINED)
    }
  }
  caseStudy_bodyperformance: file(relativePath: {eq: "wordpresswebsite/tp-casestudy-bodyperformance.png"}) {
    childImageSharp {
      gatsbyImageData(width: 664, layout: CONSTRAINED)
    }
  }
  caseStudy_mariakardami: file(relativePath: {eq: "wordpresswebsite/tp-casestudy-mariakardami.png"}) {
    childImageSharp {
      gatsbyImageData(width: 664, layout: CONSTRAINED)
    }
  }
  caseStudy_patakistravel: file(relativePath: {eq: "wordpresswebsite/tp-casestudy-patakistravel.png"}) {
    childImageSharp {
      gatsbyImageData(width: 664, layout: CONSTRAINED)
    }
  }
}
`)
	const page = data.ghostPage

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
      />
      <Helmet>
        <style type="text/css">{`${page.codeinjection_styles}`}</style>
        <script type="application/ld+json">{JSON.stringify(faqWordpress)}</script>
        <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
      </Helmet>
      <Layout viewport={'full'}>
        <article className="landing-page">
          <HeroFullScreenPhoto
            heroImage={data.hero.childImageSharp.gatsbyImageData}
            heroContent={
              `<img src="/images/logos/wordpress-logo.png" alt="Wordpress Development | Think Plus - Advertising" />
              <div class="landing-page__heading">
                <h1>THINK PLUS + WORDPRESS</h1>
                <h2>Η σύγχρονη επιλογή για την ιστοσελίδα σας</h2>
              </div>
              <div class="landing-page__heading-cta">
                <p class="h6 dec-element--line-horizontal">Επικοινωνήστε μαζί μας +30 210 6101478 <a href="tel: +302106101478" class="btn__theme ml-md-3 mt-3 mt-md-0">Καλέστε μας</a></p>
                <p class="h6 dec-element--line-horizontal">Ή συμπληρώστε τα πεδία της φόρμας για να επικοινωνήσουμε άμεσα μαζί σας <a href="#contact_form_section" class="btn__theme ml-md-3 mt-3 mt-md-0">Φόρμα Επικοινωνίας</a></p>
              </div>`
            }
          />
          <section className="landing-page__info background__theme text-align-center">
            <div className="container medium">
              <div className="row">
                <div className="col mb-5">
                  <h2><strong>Στατιστικά WordPress</strong></h2>
                  <p className="h6">Το WordPress χρησιμοποιείται από το 59,5% όλων των ιστοτόπων που χρησιμοποιούν σύστημα διαχείρισης περιεχομένου (CMS)</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <h3 className="h6">
                    Το WordPress λύνει το <br />
                    <strong className="h2">90%</strong> <br />
                    των προβλημάτων του SEO
                  </h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6">
                    Πάνω από <br />
                    <strong className="h2">52.000</strong> <br />
                    επίσημες επεκτάσεις (Official Plugins)
                  </h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6">
                    Έχει μεταφραστεί επίσημα σε <br />
                    <strong className="h2">169</strong> <br />
                    γλώσσες
                  </h3>
                </div>
              </div>
            </div>
          </section>
          <section className="why-wordpress">
            <div className="container medium">
              <header className="text-align-center">
                <h2 className="dec-header--underline">Πλεονεκτήματα WordPress</h2>
              </header>
              <ul>
                <li className="dec-element--line-horizontal">Το WordPress είναι open source, δωρεάν και με τεράστια κοινότητα υποστήριξης.</li>
                <li className="dec-element--line-horizontal">Το WordPress είναι πανίσχυρο, ευέλικτο και έχει απεριόριστες δυνατότητες παραμετροποίησης.</li>
                <li className="dec-element--line-horizontal">Το WordPress είναι φιλικό προς τις μηχανές αναζήτησης (SEO friendly).</li>
                <li className="dec-element--line-horizontal">Το περιβάλλον διαχειριστή του WordPress είναι καλά μελετημένο, εύχρηστο και εξελίσσεται συνεχώς.</li>
                <li className="dec-element--line-horizontal">Στο WordPress, η δημοσίευση περιεχομένου είναι πολύ απλή διαδικασία.</li>
              </ul>
            </div>
          </section>
          <section className="what-we-offer background__theme">
            <div className="container medium">
              <header className="text-align-center">
                <h2 className="dec-header--underline">WordPress Development</h2>
              </header>
              <div className="row checkmarks">
                <div className="col-md-6">
                  <p>Σχεδιασμός ιστοσελίδας με  σύγχρονη αισθητική και λειτουργικότητα φιλική προς τις φορητές συσκευές. Ιδιαίτερη βαρύτητα θα δοθεί στην ευχρηστία και την ταχύτητα απεικόνισης του περιεχομένου.</p>
                  <p>Μελέτη, σχεδιασμός της ιστοσελίδας καθώς και «μετάφραση» των σχεδίων / layout σε HTML, CSS και Javascript.</p>
                  <p>Εύκολη διαχείριση και ανανέωση του περιεχομένου από οποιονδήποτε.</p>
                  <p>Όλες οι εφαρμογές που θα χρησιμοποιηθούν στην ιστοσελίδα θα στηρίζονται σε βάση δεδομένων που θα συνδέεται με on-line CMS (Content Management System) για την εύκολη αλλαγή του περιεχομένου.</p>
                  <p>Εύκολη μορφοποίηση κειμένων με δυνατότητα εισαγωγής link σε λέξεις ή φράσεις και  άλλα χαρακτηριστικά.</p>
                  <p>Upload φωτογραφιών, για τις επιλεγμένες ενότητες.</p>
                  <p>Εργαλεία socializing σε όλη την ιστοσελίδα (Like, Share, Tweet κτλ).</p>
                </div>
                <div className="col-md-6">
                  <p>Στατιστικό σύστημα ιστοσελίδας (Google Analytics).</p>
                  <p>Χρήση υλικού φιλικό προς τις μηχανές αναζήτησης (SEO) και ειδικά προς τη Google.</p>
                  <p>Φιλοξενία ιστοσελίδας σε υπέρ-σύγχρονο διακομιστή (server), σε περιβάλλον Linux και με τεχνολογία βάσης δεδομένων MySQL.</p>
                  <p>Δημιουργία απεριόριστων λογαριασμών ηλεκτρονικής αλληλογραφίας (e-mail) καθώς και συνεχής υποστήριξη.</p>
                  <p>Τεχνική υποστήριξη για την ορθή λειτουργία, ανά πάσα στιγμή, τόσο για την ιστοσελίδα όσο και για τους λογαριασμούς ηλεκτρονικής αλληλογραφίας.</p>
                  <p>Εύκολη προσαρμογή και ανανέωση του περιεχομένου της ιστοσελίδας.</p>
                  <p>Δημιουργία νέων σελίδων και αφαίρεση προηγούμενων ανάλογα με τις απαιτήσεις.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="landing-page__faq">
            <div className="background__theme--dark">
              <SectionHeader
                title="WordPress"
                text="FAQ"
              />
            </div>
            <div className="container medium">
              <div className="row">
                <div className="col-md-6">
                  <Accordion
                    className="accordion--theme"
                    allowZeroExpanded={true}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τι είναι το WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Το WordPress είναι ένα σύστημα διαχείρισης περιεχομένου <span style={{fontWeight: 'bold'}}>(CMS content management system)</span> που μπορεί να χρησιμοποιηθεί για τη κατασκευή οποιασδήποτε ιστοσελίδας. Είναι γεγονός ότι αρχικά κατασκευάστηκε για την υλοποίηση blogspots. Παρόλα αυτά με τη πάροδο των χρόνων, εξελίχθηκε στο πλέον σύγχρονο, ασφαλές και δημοφιλές πρόγραμμα κατασκευής ιστοσελίδων παγκοσμίως. Είναι σημαντικό να γνωρίζετε ότι είναι λογισμικό ανοιχτού κώδικα (open source) και εξαιρετικά φιλικό τόσο με τις μηχανές αναζήτησης, όσο και τα κοινωνικά δίκτυα. Τέλος δεν χρειάζεται κανείς να γνωρίζει κάτι παραπάνω, πέραν του ότι το wordpress αποτελεί τη βάση για το 31% των websites παγκοσμίως. Στη Think Plus με την εμπειρία των 1.000 websites στα τελευταία δέκα έτη, μπορούμε να σας καθοδηγήσουμε σωστά για το πως θα αποκτήσετε μία ιστοσελίδα που αναδεικνύει την επιχείρησή σας και αποτελεί ταυτόχρονα ένα εξαιρετικό εργαλείο marketing.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Το WordPress υποστηρίζει τα ελληνικά;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Το wordpress είναι ο βασιλιάς στο βασίλειο των ιστοσελίδων. Εξελίσσεται καθημερινά και αναπτύσσεται με ταχύτητα σε κάθε κατεύθυνση. Φυσικά και υποστηρίζει Ελληνικά με το encoding UTF-8. Δεν ξέρουμε αν είναι η πλέον βέλτιστη λύση για κάθε ανάγκη αλλά σίγουρα είναι το διαχειριστικό που καλύπτει τις περισσότερες παγκοσμίως.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Το WordPress είναι εύκολο στην χρήση του;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Το wordpress είναι πανεύκολο στη χρήση του. Κάθε χρήστης, όσο εξοικειωμένος και αν είναι με υπολογιστές και απλά προγράμματα, μπορεί εύκολα και γρήγορα να διαχειριστεί πλήρως το περιεχόμενο της ιστοσελίδας του. Να ανεβάζει φωτογραφίες και βίντεο, να τροποποιεί το λεκτικό των εσωτερικών σελίδων και πολλά πολλά άλλα. Στη Think Plus, με τη παράδοση κάθε ιστοσελίδας, εκπαιδεύουμε πλήρως τοn πελάτη μας πως θα δύναται να διαχειρίζεται την ιστοσελίδα του, δημιουργώντας σχέσεις δίχως εξαρτήσεις.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Το WordPress έχει συχνές ενημερώσεις;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Το Wordpress έχει συχνές ενημερώσεις (updates) είτε στη κεντρική πλατφόρμα (core) είτε στα plugins. Στόχος φυσικά των updates είναι να βελτιστοποιήσουν τον κώδικα, κάνοντας τον πιο σύγχρονο, ασφαλή και ταχύ. Φυσικά κάθε update καλύπτει μία συγκεκριμένη ανάγκη και προτού το κάνετε θα πρέπει να είστε βέβαιοι ότι έχετε πάρει backup, καθώς μπορεί να προκύψουν θέματα ασυμβατότητας μεταξύ του κυρίου wordpress και των plugins, που δεν θα είναι εύκολο να επιλυθούν.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τα WordPress που κατασκευάζετε έχουν custom themes;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Στη Think Plus έχουμε ως βασικό κανόνα να αναλύουμε και τελικά να εξυπηρετούμε κάθε ανάγκη των πελατών μας. Αν μέσα από τη διεξοδική ανάλυση προκύπτει ότι απαιτείται η υλοποίηση της ιστοσελίδας με Custom theme, τότε αυτό προσφέρουμε στο πελάτη μας. Η εταιρεία μας εξάλλου φημίζεται για το εξαιρετικά ικανό τεχνικό της τμήμα που μετράει αρκετές διακρίσεις σε Ελλάδα και εξωτερικό. Παρόλα αυτά βασικός μας κανόνας είναι να μην κάνουμε “oversell” δηλαδή ποτέ δεν προσπαθήσουμε να σας πείσουμε στο βωμό μιας καλύτερης αμοιβής, για κάτι που δεν πιστεύουμε ότι σας χρειάζεται.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Προσφέρετε φιλοξενία με τα WordPress site σας;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Φυσικά σε υπερσύγχρονους servers με εξαιρετικά ανταγωνιστικές τιμές και εγγύηση ταχύτητας και καλής απόδοσης.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τα WordPress που κατασκευάζετε εμπεριέχουν plugins;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Ναι, όταν αυτό απαιτείται για να ικανοποιηθεί μία πολύ συγκεκριμένη ανάγκη. Φυσικά επιλέγουμε τα plugins με πολύ συγκεκριμένα και αυστηρά κριτήρια, πάντα με πολλές και θετικές αξιολογήσεις, για να είμαστε βέβαιοι ότι δεν θα δημιουργηθεί κάποιο πρόβλημα στην ιστοσελίδα. Τα plugins μπορούν να είναι τόσο ένα χρήσιμο όσο και επικίνδυνο εργαλείο. Γι αυτό χρειάζεστε τους ειδικούς της ομάδας μας.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Ποιο είναι το επίπεδο ασφαλείας στο WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Είναι εξαιρετικά μεγαλύτερο από κάθε άλλο διαχειριστικό παγκοσμίως. Παρόλα αυτά οι έμπειρη ομάδα προγραμματιστών μας θα σας προτείνει και άλλους τρόπους για να προστατευθείτε με ασφάλεια από κακόβουλους εχθρούς.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Θα μπορώ να έχω blog με το WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Φυσικά θα μπορείτε και επιβάλλεται. Με τη συχνή ανανέωση του blog βοηθάτε απίστευτα την ιστοσελίδα σας να κατακτήσει τη κορυφή των αποτελεσμάτων των μηχανών αναζήτησης. Μάλιστα στην ομάδα μας υπάρχουν εξαιρετικοί κειμενογράφοι που μπορούν να σας παρέχουν την υπηρεσία της επαγγελματικής αρθρογραφίας.</p></AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="col-md-6">
                  <Accordion
                    className="accordion--theme"
                    allowZeroExpanded={true}
                  >
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Μπορώ να ανεβάζω δικές μου φωτογραφίες και βίντεο στο WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Πανεύκολα. Βέβαια πριν το κάνετε σιγουρευθείτε ότι κατέχετε τα δικαιώματα των φωτογραφιών και βίντεο που ανεβάζετε.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Είναι εύκολο να δημιουργήσω καινούργιες σελίδες με το WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Πανεύκολο, όπως και συνολικά όλη η διαχείριση του wordpress. Mε την εκπαίδευση που σας κάνει η εξειδικευμένη ομάδα μας, η διαχείριση του wordpress σας θα είναι παιχνιδάκι.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Πόσες σελίδες έχουν τα WordPress που κατασκευάζετε;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Όσες επιθυμεί και χρειάζεται ο πελάτης. Δεν υπάρχουν τεχνικοί περιορισμοί για την ομάδα μας.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Έχουν SEO τα WordPress που κατασκευάζετε;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Φυσικά και μπορούμε να σας το αποδείξουμε εμπράκτως, με πολλά παραδείγματα πελατών μας που σε πλέον ανταγωνιστικές κατηγορίες, καταλαμβάνουν τις πρώτες θέσεις στην αναζήτηση της google.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Πόσο γρήγορα είναι τα WordPress που κατασκευάζετε;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Πάρα πολύ καθώς η ταχύτητα είναι εξαιρετικά σημαντικός παράγοντας για την επιτυχία μίας ιστοσελίδας. Ζούμε εξάλλου στην εποχή της ταχύτητας. Απαγορεύεται λοιπόν η ιστοσελίδα σας να είναι αργή.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Το WordPress υποστηρίζει E commerce;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Υποστηρίζει αλλά μόνο μέσω plugin. Δεν το συνιστούμε καθώς είναι ένα διαχειριστικό που δεν έχει υλοποιηθεί με βασική κατευθυντήρια και προσλαμβάνουσα το ηλεκτρονικό εμπόριο. Επομένως οι ελλείψεις είναι πολλές. Στη Think Plus προτείνουμε <a href="https://think-plus.gr/kataskeyi-eshop-me-shopify/" title="Κατασκευή e-shop">Shopify</a>.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Τα WordPress που κατασκευάζετε έχουν την δυνατότητα SSL;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Δεν έχουν απλώς τη δυνατότητα. Έχουν όλα SSL.</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Προσφέρετε Τεχνική υποστήριξη για WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Φυσικά με την αρχική μας κιόλας οικονομική προσφορά για την υλοποίηση της δικιάς σας ιστοσελίδας με wordpress, λαμβάνετε τη πρότασή μας είτε για πάγιο μηνιαίο support είτε για κατά περίπτωση (ad hoc).</p></AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                      <AccordionItemButton><span className="accordion__icon" aria-hidden="true"></span>Προσφέρετε Backup για WordPress;</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel><p>Φυσικά και είναι άκρως απαραίτητο ώστε να διασφαλίζεται ότι εξαλείφεται ο κίνδυνος να χαθούν δεδομένα.</p></AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>
          <Partnerships />
          <OurPartners headerTitle="ΠΕΛΑΤΕΣ" />
          <Testimonials classname="background__theme--dark" />
          <section className="landing-page__case-studies background__theme">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 background__white background-bleed__left">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={data.caseStudy_HDC.childImageSharp.gatsbyImageData}
                      alt="Healthy Derma Clinics - Think Plus | Wordpress website" />
                    <a href="https://healthydermaclinics.com/" title="Healthy Derma Clinics - Wordpress" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">HEALTHY DERMA CLINICS</h2>
                    <h3 className="h4">Εγγυημένα αποτελέσματα &amp; στοχευμένη φροντίδα</h3>
                    <p>Ένα σύγχρονο website με απαράμμιλη αισθητική.</p>
                  </div>
                </div>
              </div>
							<div className="row align-items-center">
                <div className="col-md-6 order-2 order-md-0">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">Alexandrion</h2>
                    <p>Το website του Alexandrion Wines &amp; Spirits Greece, μοιάζει με ένα δροσερό κοκτέιλ. Το νέο Wordpress site υποδέχεται τους επαγγελματίες του χώρου αλλά και τους καταναλωτές, αρκεί να είμαστε πάνω από 18 και να απολαμβάνουμε υπεύθυνα κάθε γουλιά!</p>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-0 background__white background-bleed__right">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={data.caseStudy_alexandrion.childImageSharp.gatsbyImageData}
                      alt="Bodyscape" />
                    <a href="https://alexandrionwinesandspirits.gr/" title="Alexandrion - Wordpress" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
								<div className="col-md-6 order-1 order-md-0 background__white background-bleed__left">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={data.caseStudy_bodyperformance.childImageSharp.gatsbyImageData}
                      alt="Body Performance - Think Plus | Wordpress website" />
                    <a href="https://www.bodyperformance.gr/" title="Body Performance - Wordpress" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
                <div className="col-md-6 order-2 order-md-0">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">Body Performance</h2>
                    <p>Ένα πραγματικό σύγχρονο website με δυναμικό performance για ένα personal training studio με υψηλή αισθητική και με επιλεγμένο πελατολόγιο!</p>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
								<div className="col-md-6 order-2 order-md-0">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">Maria Kardami</h2>
                    <p>Η νέα custom ιστοσελίδα της interior designer, Μαρίας Καρδάμη, είναι ένας καμβάς υψηλής αισθητικής για τον σχεδιασμό και την εσωτερική διακόσμηση. Η αρχιτεκτονική ξεπερνάει τα ψηφιακά όρια και δημιουργεί μοναδικά συναισθήματα.</p>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-0 background__white background-bleed__right">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={data.caseStudy_mariakardami.childImageSharp.gatsbyImageData}
                      alt="Maria Kardami - Think Plus | Wordpress website" />
                    <a href="https://mariakardami.com/" title="Maria Kardami - Wordpress" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
              </div>
							<div className="row align-items-center">
                <div className="col-md-6 background__white background-bleed__left">
                  <div className="case-study__photo text-align-right">
                    <GatsbyImage
                      image={data.caseStudy_patakistravel.childImageSharp.gatsbyImageData}
                      alt="Patakis Travel - Think Plus | Wordpress website" />
                    <a href="https://patakistravel.gr/" title="Patakis Travel - Wordpress" className="btn--more" target="_blank">View site <span>&rarr;</span></a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="case-study__content text-align-center">
                    <h2 className="h1">Patakis Travel</h2>
                    <p>Ένα πλήρες, λειτουργικό website που μας ταξιδεύει σε όλο τον κόσμο! Με την εύκολη και εντυπωσιακή πλοήγηση οι χρήστες προγραμματίζουν ταξίδια και εκδρομές και γνωρίζουν τις ομορφιές κάθε τόπου!</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <WhyThinkPlus />
          <ContactForm />
        </article>
      </Layout>
    </>;
}

export default pageWordpressWebsite
